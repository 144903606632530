exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-feature-tsx": () => import("./../../../src/pages/feature.tsx" /* webpackChunkName: "component---src-pages-feature-tsx" */),
  "component---src-pages-help-center-tsx": () => import("./../../../src/pages/help-center.tsx" /* webpackChunkName: "component---src-pages-help-center-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-price-index-tsx": () => import("./../../../src/pages/price/index.tsx" /* webpackChunkName: "component---src-pages-price-index-tsx" */),
  "component---src-pages-price-new-tsx": () => import("./../../../src/pages/price/new.tsx" /* webpackChunkName: "component---src-pages-price-new-tsx" */),
  "component---src-pages-terms-privacy-tsx": () => import("./../../../src/pages/terms/privacy.tsx" /* webpackChunkName: "component---src-pages-terms-privacy-tsx" */),
  "component---src-pages-terms-service-tsx": () => import("./../../../src/pages/terms/service.tsx" /* webpackChunkName: "component---src-pages-terms-service-tsx" */),
  "component---src-templates-help-center-categorized-post-titles-tsx": () => import("./../../../src/templates/help-center/CategorizedPostTitles.tsx" /* webpackChunkName: "component---src-templates-help-center-categorized-post-titles-tsx" */),
  "component---src-templates-help-center-overview-tsx": () => import("./../../../src/templates/help-center/Overview.tsx" /* webpackChunkName: "component---src-templates-help-center-overview-tsx" */),
  "component---src-templates-help-center-post-tsx": () => import("./../../../src/templates/help-center/Post.tsx" /* webpackChunkName: "component---src-templates-help-center-post-tsx" */)
}

