module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-remark-images-virtual-13bab63f63/0/cache/gatsby-remark-images-npm-7.13.1-bfe6d0ffe3-fc1032bb41.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false,"withWebp":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-8f725479b5/0/cache/gatsby-remark-autolink-headers-npm-6.13.1-0091404466-ae22112494.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-34a2b1067a/0/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-45d59a4aa5.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"픽미 | 쉽고 빠른 의사 결정 픽미로 하세요","short_name":"픽미","lang":"ko","background_color":"#fff","theme_color":"#fff","display":"standalone","icon":"src/static/favicon/favicon-32x32.png","icons":[{"src":"/favicon/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicon/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicon/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicon/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicon/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicon/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c1794129086a6d63d97a9e52a6f463df"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-2f3877a66d/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
